@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  @apply h-full bg-surface-flat;
}

code[class*="language-"],
pre[class*="language-"] {
  white-space: pre-wrap;
  word-wrap: break-word;
}

article.blog img,
article img {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.7);
}

.preview {
  background: #3a7bd5; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #3a6073,
    #3a7bd5
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #3a6073,
    #3a7bd5
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: #fffdfe;
}

.preview .tags {
  color: #dadada;
}
